/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

.spacer {
    flex: 1 1 auto;
}

.mat-button, .mat-raised-button {
    mat-icon, mat-spinner {
        margin-right: .15em;
    }
}

#findbar {
    position: fixed;
    top: calc(69px + env(safe-area-inset-top)) !important;
    width: 280px;
    height: 30px;
    left: 320px !important;
}

@media only screen and (max-width: 599px) {
    #findbar {
        top: calc(61px + env(safe-area-inset-top)) !important;
        left: 85px !important;
    }
}

@media (min-width: 889px) and (max-width: 959px) {
    #findbar {
        left: 85px !important;
    }
}

.toast-top-right {
    margin-top: env(safe-area-inset-top) !important;
}