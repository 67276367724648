
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$md-primary: (
    50 : #e3f3fb,
    100 : #b9e2f4,
    200 : #8bcfed,
    300 : #5cbce6,
    400 : #39ade0,
    500 : #169fdb,
    600 : #1397d7,
    700 : #108dd2,
    800 : #0c83cd,
    900 : #0672c4,
    A100 : #edf7ff,
    A200 : #badeff,
    A400 : #87c6ff,
    A700 : #6ebaff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-accent: (50 : #fce9e6,
    100 : #f7c8c1,
    200 : #f1a498,
    300 : #eb7f6f,
    400 : #e76350,
    500 : #e34831,
    600 : #e0412c,
    700 : #dc3825,
    800 : #d8301f,
    900 : #d02113,
    A100 : #ffffff,
    A200 : #ffd0cd,
    A400 : #ffa09a,
    A700 : #ff8880,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (50 : #ffe0e0,
  100 : #ffb3b3,
  200 : #ff8080,
  300 : #ff4d4d,
  400 : #ff2626,
  500 : #ff0000,
  600 : #ff0000,
  700 : #ff0000,
  800 : #ff0000,
  900 : #ff0000,
  A100 : #ffffff,
  A200 : #fff2f2,
  A400 : #ffbfbf,
  A700 : #ffa6a6,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($md-primary);
$app-accent: mat-palette($md-accent);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($md-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);
@import '~roboto-fontface/css/roboto/roboto-fontface.css';
@import "~@fontsource/material-icons";
@import '~ngx-toastr/toastr';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.bg-primary {
  background-color: mat-color($app-primary) !important;
}